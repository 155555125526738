import axiosInstance from "../lib/axiosInstance.js";

class SysalertService {
    #url = "/ab/rest/cpanel/sysalerts";

    getSysalerts(page) {
        return axiosInstance.get(this.#url, {
            params: {
                page: page
            }
        })
    }
}

export default new SysalertService();
