import axios from 'axios';
import axiosInstance from "../lib/axiosInstance.js";
// import {authHeader} from "../constants/authConfig";

export const ACCESS_TOKEN = "access_token";
export const REFRESH_TOKEN = "refresh_token";
export const REFRESH_STATUS = "refresh_status";

// const REFRESH_TOKEN_RENEWAL_INTERVAL = 3000 * 1000; // msec

class SecurityService {

    config = {
        method: "post",
        // url: process.env.REACT_APP_DOMAIN + "/oauth/token",
        url: process.env.VUE_APP_ABCPANEL_URL + "/ab/token",
        headers: {
            // 'Authorization': "Bearer auth header", //authHeader,
            // 'Content-Type': 'application/x-www-form-urlencoded'
            'Content-Type': 'application/json',

        },
        data: {}
    };

    removeAll() {
        localStorage.removeItem(ACCESS_TOKEN);
        localStorage.removeItem(REFRESH_TOKEN);
        localStorage.removeItem(REFRESH_STATUS);
    }

    getAccessToken() {
        return localStorage.getItem(ACCESS_TOKEN)
    }

    setAccessToken(accessToken) {
        localStorage.setItem(ACCESS_TOKEN, accessToken);
    }

    parseAccessToken() {
        const token = localStorage.getItem(ACCESS_TOKEN);

        if(!token) {
            return null
        }

        return this.parse(token);
    }

    parse(token) {
        try {
            return JSON.parse(atob(token.split('.')[1]));
        } catch (e) {
            return null;
        }
    }



    retrieveTokenData(username, password) {
        // this.stopRefreshTokenRenewal();

        // let bodyFormData = new FormData();
        // bodyFormData.set('username', username.toString());
        // bodyFormData.set('password', password.toString());
        // bodyFormData.set('grant_type', "password");
        // this.config.data = bodyFormData

        this.config.data = {
            'username': username,
            'password': password
        };

        return axiosInstance(this.config)
            .then(({data}) => {
                if (data.token !== undefined) {
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token;
                    this.setAccessToken(data.token);
                }

                // this.startRefreshTokenRenewal();
                return Promise.resolve(data);
            })
    }
}

export default new SecurityService();