<template>
  <div id="app">
    <div id="nav" v-if="isAuth" class="text-center">
      <router-link to="/">Home</router-link> |
      <router-link to="/users">Пользователи</router-link> |
      <router-link to="/incomebets">Входящие ставки</router-link> |
      <router-link to="/orderbets">Заказы ставок</router-link> |
      <router-link to="/orderbets/group">Заказы ставок (Group)</router-link> |
      <router-link to="/purses-logs">Логи по кошелькам</router-link> |
      <router-link to="/withdrawals">Заявки на вывод</router-link> |

      <router-link to="/business">Доходы</router-link> | |
      <button v-on:click="logout">выход</button>
      <hr/>
    </div>
    <div class="container-fluid">
      <div class="col-12">
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
  // @ is an alias to /src
  import SecurityService from '@/services/SecurityService'
  import router from "./router";

  export default {
    name: 'App',
    data: function() {
      return {
        isAuth: false
      }
    },
    mounted() {
      this.isAuth = SecurityService.getAccessToken() != null;
    },

    methods: {
      logout() {
        SecurityService.removeAll()
        this.isAuth = false;
        router.push({name:'Login'});
      }
    }
  }
</script>


<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;

  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
