import axiosInstance from "../lib/axiosInstance.js";

class PurseService {
    #urlPurse = process.env.VUE_APP_ABCPANEL_URL + "/ab/rest/cpanel/purses";
    #urlPurseLog = process.env.VUE_APP_ABCPANEL_URL + "/ab/rest/cpanel/purses/logs";

    getUserPurse(userId) {
        // axiosInstance.get(this.#url + "/search")

        return axiosInstance.get(this.#urlPurse + "/user/"+userId)

    }

    updatePurseBalance(purseId, amount) {
        return axiosInstance.post(this.#urlPurse + "/balance", {
            purseId: purseId,
            amount: amount
        })
    }

    correctPurseBalance(purseId, amount) {
        return axiosInstance.post(this.#urlPurse + "/correct", {
            purseId: purseId,
            amount: amount
        })
    }

    getPurseLog(userId, purseReason, page) {
        return axiosInstance.get(this.#urlPurseLog + "/user/" + userId, {
            params: {
                page: page,
                purseReason: purseReason
            }
        })
    }

    getAllLogs(page, search) {
        return axiosInstance.get(this.#urlPurseLog , {params:{
            search: search,
            page: page
        }})
    }

    getPurseControl(userId) {
        return axiosInstance.get(this.#urlPurse + '/control/'+userId )
    }

    fetFullPurseControl() {
        return axiosInstance.get(this.#urlPurse + '/control/full' )
    }

    getWithdrawalRequests(userId, page) {
        return axiosInstance.get(this.#urlPurse + '/withdrawals', {
            params: {
                userId: userId,
                page: page
            }
        }).then((response) => response.data)
    }

    getWithdrawalRequest(id) {
        return axiosInstance.get(this.#urlPurse + '/withdrawals/' + id)
            .then((response) => response.data)
    }

    executePurseAction(id, action) {
        return axiosInstance.post(this.#urlPurse + `/withdrawals/${id}/${action}`)
            .then((response) => response.data)
    }

}

export default new PurseService();