<template>
    <div class="full-purse-control">
        <div class="row">
            <div class="col-12">
                <h3>Контроль</h3>
            </div>
        </div>

        <div class="purse-control row" >
            <div class="col-md-6">
                <table class="table table-bordered">
                    <tr>
                        <td>Баланс</td>
                        <td class="text-right">$ {{purseControl.balance}}</td>
                    </tr>
                    <tr>
                        <td>Добавлено</td>
                        <td class="text-right">$ {{purseControl.addByAdmin}}</td>
                    </tr>
                    <tr>
                      <td>Выведено из системы</td>
                      <td class="text-right">$ {{purseControl.withdrawal}}</td>
                    </tr>
                    <tr>
                        <td>Списано Администратором</td>
                        <td class="text-right">$ {{purseControl.subByAdmin}}</td>
                    </tr>
                    <tr>
                        <td>Баланс 0</td>
                        <td class="text-right">$ {{purseControl.balance0}}</td>
                    </tr>
                </table>
            </div>
            <div class="col-md-6">
                <table class="table table-bordered">
                    <tr>
                        <td>В игре:</td>
                        <td class="text-right">$ {{purseControl.stakeInPlay}}</td>
                    </tr>
                    <tr>
                        <td>Профит:</td>
                        <td class="text-right">$ {{purseControl.profit}}</td>
                    </tr>
                    <tr>
                        <td>Отчисление:</td>
                        <td class="text-right">$ {{purseControl.deduction}}</td>
                    </tr>
                    <tr>
                        <td>Начисление:</td>
                        <td class="text-right">$ {{purseControl.accrual}}</td>
                    </tr>
                    <tr>
                        <td>Коммисия</td>
                        <td class="text-right">$ {{purseControl.planCommission}}</td>
                    </tr>
                </table>
            </div>

            <div class="col-12">
                <table class="table table-bordered mt-2">
                    <tr>
                        <td>1. Баланс + В игре </td>
                        <td>$ {{purseControl.sideLeft}}</td>
                    </tr>
                    <tr>
                        <td>2. Баланс 0 + Профит + Начисление - Отчисление - Коммисия:</td>
                        <td>$ {{purseControl.sideRight}}</td>
                    </tr>
                    <tr>
                        <td>Итог</td>
                        <td>$ {{purseControl.diff}}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "PurseControl",
    props: {
        purseControl: Object
    }
}
</script>
