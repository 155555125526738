import axiosInstance from "../lib/axiosInstance.js";

class DashboardService {

    #url = "/ab/rest/cpanel/board";

    getPinnacleBalance() {
        return axiosInstance.get(this.#url + '/balance/pinnacle')
    }

    // getMollybetBalance() {
    //     return axiosInstance.get(this.#url + '/balance/mollybet')
    // }

    getVoddsBalance() {
        return axiosInstance.get(this.#url + '/balance/vodds')
    }

}

export default new DashboardService();