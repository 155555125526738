<template>
    <div class="sysqlerts-list">
        <div>
            <h3>Оповещения</h3>
            <table class="table table-bordered">
                <tr v-for="alert in alerts" v-bind:key="alert.id">
                    <td width="20px">{{alert.id}}</td>
                    <td>{{alert.message}}</td>
                    <td width="10px">{{alert.status}}</td>
                </tr>
            </table>
            <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    v-on:change="selectPage"
                    aria-controls="sysqlerts-list"
            ></b-pagination>
        </div>
    </div>
</template>

<script>
    import SysalertService from "../services/SysalertService";

    export default {
        name: "SysalertsList",
        data() {
            return {
                currentPage: 1,
                totalRows: 0,
                perPage:1,
                alerts: [],
            }
        },

        methods: {
            selectPage(page) {
                this.currentPage=page;
                this.loadAlerts();
            },
            loadAlerts(){
                var self = this;
                SysalertService.getSysalerts(self.currentPage-1)
                    .then((response) => {
                        self.alerts = response.data.content;

                        self.currentPage = response.data.number+1;
                        self.totalRows = response.data.totalElements;
                        self.perPage = response.data.size;
                    })

            },
        },
        mounted() {
            this.loadAlerts()
        }
    }
</script>

