<template>
    <div class="pinnacle-balance">
        <h3>Pinnacle</h3>
        <div  v-if="null !== balance">
            <table class="table table-bordered">
                <tr>
                    <td>Доступно:</td>
                    <td class="text-right">{{balance.availableBalance}} {{balance.currency}}</td>
                </tr>
                <tr>
                    <td>Неоплаченные транзакции:</td>
                    <td class="text-right">{{balance.outstandingTransactions}} {{balance.currency}}</td>
                </tr>
                <tr>
                    <td>Данный кредит:</td>
                    <td class="text-right">{{balance.givenCredit}} {{balance.currency}}</td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
    import DashboardService from "../services/DashboardService";

    export default {
        name: "PinnacleBalance",
        data(){
            return {
                balance: null
            }
        },
        methods: {

        },
        mounted() {
            var self = this;
            DashboardService.getPinnacleBalance()
            .then((response) => {
                self.balance = response.data;
            })
        }
    }
</script>
