//import store from '../../store';
import axios from 'axios';
import SecurityService from "../services/SecurityService.js";

// import {store} from "../../store";
// import {setFormMessagesAction} from "../../store/formMessages/actions";

// import {errorResponseMessage, errorsResponseMessages} from "../../utils/MessageUtils";

let axiosOptions = {
    // baseURL: API_DOMAIN,
    // timeout: 5000,
    headers: {
        // contentType: 'application/json',
        Authorization: {
            toString () {
                const token = SecurityService.getAccessToken();
                if (null !== token) {
                    return "Bearer " + token;
                }

                return "";
            }
        }
    }
};

const axiosInstance = axios.create(axiosOptions);

axiosInstance.interceptors.response.use(
    response => response,
    error => {
        // const originalRequest = error.config;
        if ((SecurityService.getAccessToken() && error.response === undefined)
            || (error.response !== undefined && error.response.status === 401)) {

            if(!SecurityService.getRefreshStatus()) {
                SecurityService.setRefreshStatus();
                SecurityService.refreshToken().then(() => {
                    window.location.reload();
                });
                return Promise.reject(error);
            } else {
                SecurityService.removeAll();
                window.location.href = "/login";
                window.location.reload();
            }
        }

        // else if (error.response !== undefined && error.response.status === 400) {
        //     const {data} = error.response;
        //
        //     if (data.errors !== undefined && data.errors.length > 0) {
        //         store.dispatch(setFormMessagesAction(errorsResponseMessages(data.errors)));
        //     } else {
        //         var message = error.message;
        //         var field = "defaultMessage";
        //         store.dispatch(setFormMessagesAction(errorResponseMessage(message, field)));
        //     }
        // }

        // else if (error.response !== undefined && error.response.status === 500) {
        //     store.dispatch(setFormMessagesAction(errorResponseMessage(error.response.data.message, "defaultMessage")));
        // }

        return Promise.reject(error);
    }
);

export default axiosInstance;