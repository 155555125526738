<template>
  <div class="home row">
    <div class="col-6">
      <div class="row">
        <div class="col-6">
          <PinnacleBalance></PinnacleBalance>
        </div>
        <div class="col-6">
          <VoddsBalance></VoddsBalance>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <PurseControl v-bind:purse-control="purseControl"></PurseControl>
        </div>
      </div>
    </div>

    <div class="col-6">
      <div class="row">
        <SysalertsList></SysalertsList>
      </div>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import PinnacleBalance from "@/components/PinnacleBalance";
import VoddsBalance from "@/components/VoddsBalance";
import SysalertsList from "@/components/SysalertsList";
import PurseControl from "@/components/PurseControl";
import PurseService from "@/services/PurseService";

export default {
  name: 'Home',
  components: {
    SysalertsList,
    VoddsBalance,
    PinnacleBalance,
    PurseControl
    // HelloWorld
  },
  data() {
    return {
      purseControl: null
    }
  },
  mounted() {
    var self = this;
    // axiosInstance.get(process.env.VUE_APP_ABCPANEL_URL + "/ab/rest/cpanel");
    PurseService.fetFullPurseControl()
    .then((response) => {
      self.purseControl = response.data;
    })
  }
}
</script>
