<template>
    <div class="pinnacle-balance">
        <h3>Vodds</h3>
        <div v-if="null !== balance">
            <table class="table table-bordered">
                <tr>
                    <td>Баланс:</td>
                    <td>{{balance.credit}}</td>
                    <td class="text-right">{{balance.currency}}</td>
                </tr>
              <tr>
                <td>В игре:</td>
                <td>{{balance.outstanding}}</td>
                <td class="text-right">{{balance.currency}}</td>
              </tr>
              <tr>
                <td>Выигрыш:</td>
                <td>{{balance.pl}}</td>
                <td class="text-right">{{balance.currency}}</td>
              </tr>
            </table>
        </div>
    </div>
</template>

<script>
    import DashboardService from "../services/DashboardService";

    export default {
        name: "VoddsBalance",
        data(){
            return {
                balance: null
            }
        },
        methods: {

        },
        mounted() {
            var self = this;
            DashboardService.getVoddsBalance()
            .then((response) => {
                self.balance = response.data;
            })
        }
    }
</script>
